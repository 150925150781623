import React from 'react';
import WorkshopModule from '../../components/courses/WorkshopModule';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Link } from 'gatsby';
import HeaderBar from '../../components/courses/HeaderBar';
import Img from 'gatsby-image';
import { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import haztuhorario1 from '../../images/samples/haztuhorario/haztuhorario-preview.png';
import finits1 from '../../images/samples/finits/finits-preview.png';
import toldos1 from '../../images/samples/toldos/toldos-preview.png';
import vazcor1 from '../../images/samples/vazcor/vazcor-preview.png';
import withTranslations from '../../components/hoc/withTranslations';

const examples = [haztuhorario1, finits1, toldos1, vazcor1];

const Frontend = () => {
  const [exampleImage, setExampleImage] = useState('');

  let images = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "courses/Banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      html: file(relativePath: { eq: "courses/HTML&CSS.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      git: file(relativePath: { eq: "courses/Git.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      javaScript: file(relativePath: { eq: "courses/JavaScript.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      react: file(relativePath: { eq: "courses/ReactJS.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      setExampleImage(examples[count]);
      count++;
      count > 2 && (count = 0);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <Seo
        title='Frontend Web Developer Workshop'
        description={
          'Curso de desarrollo frontend donde se enseñará a crear páginas web desde cero utilizando HTML, CSS, JavaScript, ReactJS y Git.'
        }
        keywords={[
          `git`,
          `css`,
          `html`,
          `web`,
          `javascript`,
          `react`,
          `reactjs`,
          `curso`,
          `taller`,
          `paginas`,
          `desarrollo`,
          `workshop`,
          `frontend`,
          `development`,
          `aplicacion`,
          `app`
        ]}
      />

      <div className='header'>
        <div className='header-text'>
          <h1>Frontend Web Developer</h1>
          <h2>WORKSHOP</h2>
        </div>
        <div className='image'>
          <Img fluid={images.banner.childImageSharp.fluid} width='100%' />
        </div>
      </div>

      <section className='global-section'>
        <p>
          Asiste a este taller de desarrollo Frontend. Aprenderás a crear páginas web <b>desde cero</b> utilizando HTML,
          CSS, JavaScript, ReactJS y Git.
        </p>
      </section>

      <section className='global-section centered'>
        <div className='what-is-container'>
          <h3 className='what-is'>¿Qué es Frontend?</h3>
          <div className='divider'></div>
          <p className='what-is-text'>
            Frontend es la parte visual de una aplicación con la que el usuario interactúa directamente. Son todas las
            tecnologías de diseño y desarrollo web que corren en el navegador y que se encargan de la interactividad con
            los usuarios.
          </p>
        </div>
      </section>

      <section className='global-section'>
        <HeaderBar>¿Para qué?</HeaderBar>
        <p>
          El objetivo es proveer el conocimiento necesario para desarrollar páginas web. Creemos que son indispensables
          para cualquier proyecto, producto, negocio o idea. Además, al estar en Internet estás al alcance de todas las
          personas. Por lo tanto, tienes la posibilidad de generar impacto mundial.
        </p>
      </section>

      <section className='global-section'>
        <h2 style={{ textAlign: 'center' }}>3 módulos, 3 días</h2>

        <div className='workshops'>
          <WorkshopModule
            icon={images.html.childImageSharp.fluid}
            name='HTML & CSS'
            description='Son los fundamentos de cualquier sitio o aplicación web. HTML es un lenguaje de etiquetas que se utiliza para darle estructura a un documento. CSS es un lenguaje de hojas de estilos que nos permite agregar colores, tipografía, sombras y más.'
            hours='6'
            firstDate='1 de noviembre'
            secondDate='9 de noviembre'
            price='150'
          />
          <WorkshopModule
            icon={images.javaScript.childImageSharp.fluid}
            name='JavaScript'
            description='Lenguaje de programación funcional que aporta el comportamiento y la interactividad de una aplicación. Aprenderás a admitir entradas de texto, obtener información de fuentes externas, manejar distintos eventos, hacer transformaciones con los datos.'
            hours='6'
            firstDate='8 de noviembre'
            secondDate='16 de noviembre'
            price='200'
          />
          <WorkshopModule
            icon={images.react.childImageSharp.fluid}
            name='ReactJS'
            description='Es un framework declarativo, flexible y eficiente para la creación de interfaces de usuario. Las aplicaciones web más populares utilizan ReactJS: Facebook, Netflix, Instagram, Pinterest, Twitter, entre otras. Podrás combinar tus conocimientos en una interfaz de usuario agradable.'
            hours='6'
            firstDate='15 de noviembre'
            secondDate='23 de noviembre'
            price='250'
          />
        </div>

        <div className='global-section deal'>
          <p>Adquiere los 3 módulos por</p>
          <h1>$500.00</h1>
        </div>

        <h2 style={{ textAlign: 'center' }}>Bonus</h2>
        <div className='bonus'>
          <div>
            <Img
              style={{ width: 100, height: 100, margin: 10 }}
              imgStyle={{ objectFit: 'contain' }}
              fluid={images.git.childImageSharp.fluid}
              alt='Icono de modulo'
            />
            <p className='git-title'>Git</p>
          </div>
          <p className='description'>
            Git es una herramienta que se utiliza para controlar las versiones de un proyecto de software. Facilita el
            proceso de desarrollo permitiéndote volver a un estado funcional en cualquier momento y habilitando que
            distintos integrantes de un equipo puedan trabajar simultáneamente.
          </p>
        </div>
      </section>

      <section className='global-section'>
        <HeaderBar>Requerimientos</HeaderBar>
        <ul>
          <li>Laptop</li>
          <li>Motivación</li>
          <li>Curiosidad</li>
          <li>Ganas de aprender</li>
        </ul>
      </section>

      <section className='global-section slider'>
        <HeaderBar>Ejemplos desarrollados por PZ</HeaderBar>
        <img alt='Portafolio' src={exampleImage} width='100%' />
      </section>

      <section className='global-section'>
        <HeaderBar>Instructores</HeaderBar>

        <p>
          Creadores de{' '}
          <a style={{ color: '#FFA500' }} href='https://haztuhorario.com'>
            haztuhorario.com
          </a>
          . Han estado involucrado en docenas de aplicaciones web para fines educativos y comerciales. Tienen
          experiencia en la industria manufacturera, creando software especializado capaz de resolver problemáticas y
          generar ahorros económicos para la empresa.
        </p>
      </section>

      <style jsx>{`
        .header {
          display: flex;
          justify-content: space-around;
          /* flex-wrap: wrap; */
        }

        .cit-logo {
          max-width: 500px;
        }

        .centered {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .header-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .image {
          margin-bottom: 20px;
          width: 70%;
        }

        .deal {
          text-align: center;
        }

        img {
          max-width: 700px;
        }

        .git-title {
          text-align: center;
          margin-bottom: 10px 20px;
          font-size: 30px;
          font-weight: bold;
          color: #ffa500;
        }

        .bonus {
          padding: 20px;
          margin: 10px;
          display: flex;
          justify-content: space-around;
          background-color: #464646;
          border-radius: 8px;
          align-items: center;
        }

        .slider {
          margin: 20px 0;
        }

        section {
          margin: 50px 0;
        }

        .workshops {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }

        .register-button {
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 20px 0;
        }

        .what-is-container {
          background-color: #464646;
          border-radius: 8px;
          max-width: 730px;
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .what-is {
          margin-bottom: 0;
          flex: none;
          font-weight: 700;
          text-align: center;
          font-size: 32px;
          max-width: 200px;
        }

        .what-is-text {
          max-width: 650px;
          margin: 0;
        }

        .divider {
          background: #ffa500;
          height: 130px;
          width: 10px;
          margin: 0 30px;
        }

        @media only screen and (max-width: 730px) {
          .header {
            flex-direction: column;
          }

          .image {
            width: 100%;
          }

          .what-is-text {
            text-align: center;
          }

          .divider {
            background: #ffa500;
            height: 10px;
            width: 200px;
            margin: 20px 0;
          }

          .what-is-container {
            flex-direction: column;
          }

          .bonus {
            flex-direction: column;
          }
        }
      `}</style>
    </Layout>
  );
};

export default withTranslations()(Frontend);

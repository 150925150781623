import React from 'react';
import Img from 'gatsby-image';

export default ({
  name,
  price,
  description,
  icon,
  hours,
  firstDate,
  secondDate
}) => (
  <div className='container'>
    <Img
      style={{ width: 100, height: 100, margin: 10 }}
      imgStyle={{ objectFit: 'contain' }}
      fluid={icon}
      alt='Icono de modulo'
    />
    <p className='name'>{name}</p>
    <p className='description'>{description}</p>
    <div className='bottom'>
      <p className='hours'>{hours} horas</p>

      <small>8:00 - 14:00</small>
      <p className='date'>{secondDate}</p>
      <p className='date'>25 lugares</p>
      <p className='price'>
        <b>${price}.00</b>
      </p>
    </div>

    <style jsx>{`
      .container {
        padding: 20px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 300px;
        background-color: #464646;
        border-radius: 8px;
        align-items: center;
        margin-bottom: 40px;
      }

      .name {
        margin-bottom: 10px 20px;
        font-size: 30px;
        font-weight: bold;
        color: #ffa500;
      }

      .hours {
        margin-bottom: 20px;
      }

      .bottom {
        margin-top: auto;
      }

      .description {
        font-size: 20px;
        text-align: left;
      }

      .date {
        margin-bottom: 20px;
      }

      .price {
        font-size: 25px;
        font-weight: bold;
      }
    `}</style>
  </div>
);
